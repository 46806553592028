import React, { useState } from 'react';
import axios from 'axios';
import './SqlQueryComponent.css';

const SqlQueryComponent = () => {
  const [query, setQuery] = useState('');
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Add user query to conversation
      setConversation((prev) => [...prev, { role: 'user', content: query }]);

      // Call to Cloudflare Worker for SQL generation
      const sqlResponse = await axios.post('https://pi360-llm-util.purnendu-cse.workers.dev/sql-gen', { content: query });
      const generatedQuery = sqlResponse.data.result.response.trim();
      const base64Query = btoa(generatedQuery);

      // Call to Cloudflare Worker for pi360 response
      const pi360Response = await axios.get('https://pi360-llm-util.purnendu-cse.workers.dev/pi360', {
        params: {
          query: base64Query,
        },
      });

      const pi360Content = pi360Response.data.content;

      // Call to Cloudflare Worker for Llama response
      const llamaResponse = await axios.post('https://pi360-llm-util.purnendu-cse.workers.dev/ai', {
        messages: [
          { role: 'system', content: JSON.stringify(pi360Content) },
          { role: 'user', content: query },
        ],
      });

      // Add Llama response to conversation
      setConversation((prev) => [
        ...prev,
        { role: 'assistant', content: JSON.stringify(llamaResponse.data.response).replace(/\\n/g, '\n') },
      ]);
      setQuery(''); // Clear the input field
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="sql-query-container">
      <h1>Chat pi360 - LLM for pi360</h1>
      <div className="chat-window">
        {conversation.map((message, index) => (
          <div
            key={index}
            className={`chat-bubble ${message.role === 'user' ? 'user-message' : 'assistant-message'}`}
          >
            <pre>{message.content}</pre>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="input-form">
        <input type="text" value={query} onChange={handleChange} placeholder="Enter your query" />
        <button type="submit" disabled={loading}>Submit</button>
      </form>

      {loading && <p>Loading...</p>}
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default SqlQueryComponent;
